import $ from 'jquery';

function CommonCtrl() {
	this.init();
}

CommonCtrl.prototype.init = function() {
  this.faq();
  this.smoothScroll();
}

CommonCtrl.prototype.faq = function () {
  const $faq = $('.faq-item');

  $faq.each(function () {
    const $self = $(this);

    // if (!$self.hasClass('active')) {
    //   $self.removeClass('active').find('dd').stop().slideUp('fast');
    // }
    $self.removeClass('active').find('dd').stop().slideUp();

    $self.find('dt').on('click', function () {
      const $dt = $(this);
      const $parent = $dt.parent();
      const $dd = $dt.next('dd');

      if ($parent.hasClass('active')) {
        $parent.removeClass('active');
        $dd.stop().slideUp('fast');
      } else {
        $parent.addClass('active');
        $dd.stop().slideDown('fast');
      }
    });
  });
}

CommonCtrl.prototype.smoothScroll = function () {
  const $hash = $('a[href^="#"]');
  $hash.on('click', function (e) {
    e.preventDefault();

    console.log(this.hash)
    const $target = $(this.hash === '#' ? 'html' : this.hash);
    if ($target.length > 0) {
      $('html, body').stop().animate({
        scrollTop: $target.offset().top
      }, 700, 'swing');
      location.hash = this.hash;
    }
  });
}


export default CommonCtrl;

